import request from '../util/request'

export const save = (data) => { 
    return request({
        url: "/pc/baseInfo/save",
        method: "post",
        data:data
    })
}
// export const getNewsById = (params) => { 
//     return request({
//         // url: `/pc/news/getNewsById?newsId=${data.newsId}`,
//         url: '/pc/news/getNewsById',
//         method: "get",
//         params 
//     })
// }