import { render, staticRenderFns } from "./Russia.vue?vue&type=template&id=361ceaa1&scoped=true&"
import script from "./Russia.vue?vue&type=script&lang=js&"
export * from "./Russia.vue?vue&type=script&lang=js&"
import style0 from "./Russia.vue?vue&type=style&index=0&id=361ceaa1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361ceaa1",
  null
  
)

export default component.exports