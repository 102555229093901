<template>
  <div>
    <Header></Header>
    <div class="lone">
      <div class="banner">
        <div class="main_left">
          <div
            class="nav"
            v-for="(item, index) in navarr[0][num]"
            :key="index"
            @click="router(index)"
          >
            <div class="flex_row">
              <div>{{ item }}</div>
              <div>></div>
            </div>
          </div>
        </div>
        <div class="main_right">
          <div class="manager_box">
            <!-- <div class="fs30">{{ us[num].title }}</div> -->
            <div><img src="../img/背景拷贝4.png" alt="" /></div>
            <div class="item-box">
              <div class="item">
                <div class="imgbox">
                  <img src="../img/矢量智能对象3.png" alt="" />
                </div>
                <div class="text">
                  Покупая сейчас в рублях, вы легко превращаете их в доллары в
                  будущем
                </div>
              </div>
              <div class="item">
                <div class="imgbox">
                  <img src="../img/矢量智能对象2.png" alt="" />
                </div>
                <div class="text">
                  Совершите покупку прямо сейчас, имея при себе только
                  загранпаспорт
                </div>
              </div>
              <div class="item">
                <div class="imgbox">
                  <img src="../img/矢量智能对象1.png" alt="" />
                </div>
                <div class="text">
                  Застройщик предлагает опцию обратного выкупа квартиры через 5
                  лет после сдачи. Таким образом, инвестируя сейчас рубли, вы
                  гарантировано вернёте вложенную сумму долларами.
                </div>
              </div>
            </div>
            <div class="Rtitle">
              <p>Преимущество</p>
              <p>таиланда</p>
            </div>
            <div class="advantage-box">
              <div class="left">
                <img src="../img/e8d8bb556cf9ad1d4ef6fcc5af87efa.png" alt="" />
                <div class="right">
                  <li>
                    Благодаря отдаленности от Европы и Америки, остается
                    нейтральным государством
                  </li>
                  <li>
                    Обязательство не присоединяться к системе глобального
                    налогообложения CRS
                  </li>
                  <li>
                    Правительство иностранного государства не имеет права
                    проверять личные активы в Таиланде
                  </li>
                  <li>
                    Таиланд является наиболее популярным местом для российских
                    туристов в юго-восточной Aзии
                  </li>
                </div>
              </div>
              <div class="left">
                <img src="../img/a9c85f4042de286e106d8ec949a070c.png" alt="" />
                <div class="right">
                  <li>Экономика и политика стабильны</li>
                  <li>Конституция защищает активы россиян в Таиланде</li>
                  <li>
                    Для хранения тайской собственности понадобятся только
                    фотографии загранпаспорта
                  </li>
                  <li>
                    30-летняя пенсионная виза, весь год тепло, природа очень
                    красивая
                  </li>
                  <li>Низкая цена, низкая себестоимость жизни</li>
                </div>
              </div>
              <div class="Rtext">
                <p>Через 5 лет после сдачи квартиры</p>
                <p>застройщик может выкупить её по первоначальной цене,</p>
                <p>чтобы сохранить стоимость ваших активов</p>
              </div>
            </div>
            <div class="Rtitle">
              <p>УСЛОВИЯ ОПЛАТЫ</p>
            </div>
            <div class="forfive">
              <div class="imgbox2"><img src="../img/img1.png" alt="" /></div>
              <div class="down">
                <div class="fortitle">Цена</div>
                <div
                  class="forcontent"
                  v-html="
                    `постоянная собственность ~ 84,000 USD<br>90 - летняя аренда~
                  56,000 USD`
                  "
                ></div>
              </div>
            </div>
            <!-- <div class="forfive">
              <div class="imgbox2"><img src="../img/img3.png" alt="" /></div>
              <div class="down">
                <div class="fortitle">Системы оплаты</div>
                <div class="forcontent">
                  Наличными или банковским переводом в рублях единовременно
                </div>
              </div>
            </div> -->
            <div class="forfive">
              <div class="imgbox2"><img src="../img/img3.png" alt="" /></div>
              <div class="down">
                <div class="fortitle">Системы оплаты</div>
                <div class="forcontent">
                  Наличными или банковским переводом в рублях, долларах США и тайских батах единовременно
                </div>
              </div>
            </div>
            <!-- <div class="forfive">
              <div class="down">
                <div class="fortitle2">Налог</div>
                <div class="forcontent2">
                  Земельный налог — 2%. Нет налогов на владение, передачу в
                  наследство и каких-либо других налогов
                </div>
              </div>
              <div class="imgbox3"><img src="../img/img4.png" alt="" /></div>
            </div> -->
            <div class="forfive">
              <div class="imgbox2"><img src="../img/img4.png" alt="" /></div>
              <div class="down">
                <div class="fortitle">Налог</div>
                <div class="forcontent">
                  Земельный налог — 2%. Нет налогов на владение, передачу в
                  наследство и каких-либо других налогов
                </div>
              </div>
            </div>
            <div class="Rtext">
              <p>При покупке квартиры до 31 марта 2023 года, вам </p>
              <p>будут подарены билеты на остров Самуи, и вы сможете</p>
              <p>увидеть своими глазами шоу-рум проекта</p>
            </div>
            <div class="bigbox">
              <div class="Rtitle">
                <p>Фотографии основных</p>
                <p>типов квартир</p>
              </div>
              <div class="footerimgbox">
                <img src="../img/网球场.png" alt="" />
              </div>
              <div class="footerimgbox">
                <img src="../img/泳池.png" alt="" />
              </div>
              <div class="lastbox">
                <div class="last-left">
                  <img src="../img/keting.png" alt="" />
                </div>
                <div class="last-right">
                  <div class="lastimgup">
                    <div class="up1">
                      <img src="../img/woshi1.png" alt="" />
                    </div>
                    <div class="up2">
                      <img src="../img/chufang.png" alt="" />
                    </div>
                  </div>

                  <div class="lastimgup">
                    <div class="up2">
                      <img src="../img/weishengjian.png" alt="" />
                    </div>
                    <div class="up1"><img src="../img/woshi.png" alt="" /></div>
                  </div>
                </div>
              </div>

              <div class="sub">
                <div class="submit-title">ОСТАВИТЬ ЗАЯВКУ</div>
                <div class="submit-content">
                  Оставьте Ваши контакты, и мы свяжемся с Вами в ближайшее
                  время!
                </div>
                <el-form ref="submitobj" :model="submitobj" label-width="80px">
                  <div class="inde">Имя и фамилия:</div>
                  <el-form-item label="">
                    <el-input v-model="submitobj.name"></el-input>
                  </el-form-item>
                  <div class="inde">Телефон:</div>
                  <el-form-item label="">
                    <el-input v-model="submitobj.number"></el-input>
                  </el-form-item>
                  <div class="inde">Электронная почта:</div>
                  <el-form-item label="">
                    <el-input v-model="submitobj.email"></el-input>
                  </el-form-item>
                  <div class="buttonBox">
                    <el-button type="primary" @click="submit()" :disabled="flag"
                      >Сдать</el-button
                    >
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script type='text/javascript' src='https://www.wjx.top/handler/jqemed.ashx?activity=evIyfoR&width=760&source=iframe'></script>

<script>
import { save } from "../api/russia";
import Header from "../components/Herder.vue";
import Footer from "../components/Footer.vue";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },
  components: { Header, Footer },
  data() {
    return {
      submitobj: {
        name: "",
        number: "",
        email: "",
      },
      flag: false,

      textContent: `постоянная собственность ~ 84,000 USD<br>
      90 - летняя аренда~ 56,000 USD`,
      encnimg: [
        {
          imgurl: require("../img/headerimg.png"),
        },
        {
          imgurl: require("../img/headerenimg.png"),
        },
      ],
      us: [
        {
          title: "Содержание формы",
          imgurl: require("../img/中文.png"),
        },
        {
          title: "Содержание формы",
          imgurl: require("../img/英文.png"),
        },
      ],
      navarr: [
        {
          0: ["свяжитесь с нами"],

          1: ["свяжитесь с нами"],
        },
      ],
    };
  },
  methods: {
    open() {
      this.$alert("Представлять успех", "", {
        confirmButtonText: "OK",
        callback: (action) => {
          this.$message({
            type: "info",
            message: `action: ${action}`,
          });
        },
      });
    },
    async submit() {
      const { msg, status } = await save(this.submitobj);
      if (msg == "添加成功") {
        this.open();
        this.flag = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.submit-content {
  font-size: 20px;
  text-align: center;
  color: #666666;
  margin-bottom: 50px;
}
.inde {
  text-indent: 80px;
  font-size: 20px;
}
.submit-title {
  font-size: 40px;
  text-align: center;
  // color: rgb(0, 200, 255);
  margin-bottom: 50px;
}
/deep/.buttonBox {
  margin: 0 auto;
  text-align: center;
}
/deep/.el-input {
  width: 80%;
  margin: 40px auto;
  text-align: center;
}
.sub {
  width: 800px;
  height: 1000px;
}
.lastbox {
  display: flex;
  height: 320px;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  .last-left {
    width: 265px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .last-right {
    display: flex;
    flex-direction: column;
    .lastimgup {
      display: flex;
      .up1 {
        width: 285px;
        height: 160px;
      }
      .up2 {
        height: 160px;
        flex: 1;
      }
    }
  }
}
.footerimgbox {
  margin-bottom: 40px;
  img {
    width: 100%;
  }
}
.bigbox {
  margin-top: 50px;
  height: 1900px;
  background-color: #eeeeee;
}
.fortitle2 {
  font-size: 40px;
  // display: flex;
  // line-height: 60px;
  height: 80px;
  margin-bottom: 20px;
  // align-items: flex-end;
  line-height: 120px;
  width: 600px !important;
  text-align: right !important;
}
.forcontent2 {
  font-size: 24px;
  justify-content: center;
  flex: 1;
  color: #666666;
  text-align: right;
}
.forfive {
  display: flex;
  margin-bottom: 40px;
  width: 800px;
  background-color: #d6dbe8;
}
.down {
  display: flex;
  flex-direction: column;
  flex: 1;

  .fortitle {
    font-size: 40px;
    display: flex;
    // line-height: 60px;
    height: 80px;
    margin-bottom: 20px;
    align-items: flex-end;
  }
  .forcontent {
    font-size: 24px;
    justify-content: center;
    flex: 1;
    color: #666666;
  }
}
.imgbox2 {
  width: 200px;
  height: 200px;
  display: flex;
  img {
    width: 80%;
    height: 80%;
    margin: auto;
  }
}
.imgbox3 {
  width: 200px;
  height: 200px;
  display: flex;
  float: right;
  img {
    width: 80%;
    height: 80%;
    margin: auto;
  }
}
.Rtext {
  text-align: center;
  font-size: 20px;
  color: gray;
  line-height: 25px;
  margin-top: 30px;
}
.advantage-box .Rtext {
  text-align: center;
  font-size: 20px;
  color: gray;
  line-height: 25px;
  margin-top: 30px;
}
.advantage-box {
  height: 1000px;
  background-color: #eeeeee;
}
.left {
  width: 350px;
  height: 380px;
  // background-color: red;
  position: relative;
  margin-left: 20px;
  margin-top: 50px;

  .right {
    height: 350px;
    width: 460px;
    // background-color: #a08e75;
    background-color: white;
    position: absolute;
    left: 300px;
    top: 15px;
    padding-left: 25px;
    box-sizing: border-box;

    li {
      font-size: 20px;
      text-indent: -20px;
      padding: 15px;
    }
  }
}
.Rtitle {
  margin: 30px 0;
}
.Rtitle p {
  color: #003a94;
  font-size: 35px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 800;
}
.text {
  font-weight: 600;
  font-size: 18px;
  padding: 5px;
  flex: 1;
}
.imgbox {
  width: 140px;
  height: 140px;
  display: flex;
  img {
    width: 50%;
    height: 50%;
    margin: auto;
    display: block;
  }
}
.item-box {
  height: 420px;
  background-color: #eeeeee;
  .item {
    display: flex;
    height: 33.3%;
    .text {
      display: flex;
      margin: auto;
    }
  }
  margin-bottom: 50px;
}
.manager_text {
  margin: 0.4rem auto;
  font-size: 0.17rem;
  line-height: 0.3rem;
  color: #666666;
  margin-bottom: 0.3rem;
}
.mating_img {
  img {
    width: 100%;
  }
}
.none {
  border: none !important;
}
.mat {
  margin: 0.3rem auto;
  border-bottom: 0.02rem solid #dad5ce;
}
.row {
  display: flex;
  width: 8rem;

  margin: 0.3rem auto;
}
.dis {
  width: 50%;
  display: flex;
  img {
    height: 0.3rem;
  }
  div {
    font-size: 0.17rem;
    line-height: 0.3rem;
    margin-left: 0.1rem;
  }
}
.manager_box {
  width: 8rem;
  height: 100%;
  margin: 0 auto;
}
.mating_img {
  img {
    width: 100%;
  }
}
.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;
  img {
    width: 100%;
  }
}
.fs30 {
  margin: 0.8rem auto;
  font-size: 0.3rem;
  text-align: center;
}
.lone {
  width: 100%;
  background-color: #f3f3f4;
  overflow: hidden;
}
.flex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0.65rem;
  padding-left: 0.5rem;
  padding-right: 0.2rem;
}
.nav {
  width: 2.7rem;
  height: 0.65rem;
  background: white;
  margin-bottom: 0.05rem;
}
.nav:hover {
  background-color: #a08e75;
  color: white;
}
.banner {
  width: 12.6rem;

  margin: 1rem auto;
  display: flex;
}
.main_left {
  width: 3.47rem;
  height: 100%;
  border-right: 0.02rem solid #dad5ce;
}
.main_right {
  flex: 1;
}
</style>